import dynamic from 'next/dynamic';
import type { ReactElement } from 'react';
import { useState } from 'react';

import CustomMetatag from '@/components/layouts/custom-metatag';
import Layout from '@/components/layouts/lcv';
import { SPLASH_DATA } from '@/components/sections/campaigns/maxforce-maxdeal/content';
import LCVHomeBanner from '@/components/sections/einstein/home/banner';
import { IMY24SpecialOffer } from '@/components/sections/my24/home/special-offer';
import { SplashPage } from '@/components/sections/splash';
import {
  SPLASH_AVAILABLE,
  SplashSpecialEvent,
} from '@/components/sections/splash/special-event';
import { MENU_LCV_SPECIAL_OFFER } from '@/contents/lcv/link';
import {
  CTA_DATA,
  ENGINE_DATA,
  GEAR_DATA,
  HERO_BANNER_DATA,
  LINEUP_CAROUSEL_DATA,
  META_TAG_DATA,
} from '@/contents/lcv/product/maxforce/home';
import { getLCVSplashData, getSaleOffers } from '@/services/contentstack/';

const Lineup = dynamic(
  () =>
    import('@/components/sections/einstein/home/lineup').then(
      (mod) => mod.LCVHomeLineup
    ),
  {
    ssr: false,
  }
);

const SectionEinsteinEngine = dynamic(
  () =>
    import('@/components/sections/einstein/home/engine').then(
      (mod) => mod.HomeEngine
    ),
  {
    ssr: false,
  }
);

const SectionEinsteinGear = dynamic(
  () =>
    import('@/components/sections/einstein/home/gear').then(
      (mod) => mod.SectionEinsteinGear
    ),
  {
    ssr: false,
  }
);

const SpecialOffer = dynamic(
  () =>
    import('@/components/sections/my24/home/special-offer').then(
      (mod) => mod.LCVHomeOffer
    ),
  {
    ssr: false,
  }
);

const CTA = dynamic(() => import('@/components/shared/cta'), {
  ssr: false,
});

const FooterLCVGroup = dynamic(
  () => import('@/components/layouts/lcv/footer-group'),
  {
    ssr: false,
  }
);

export async function getStaticProps() {
  const RES_OFFER = await getSaleOffers('lcv', 5);
  const RES_SPLASH = await getLCVSplashData();

  RES_OFFER.data.entries = RES_OFFER.data.entries.map((o) => ({
    name: o?.name,
    img: {
      url: o?.img?.url,
    },
    category: o.category.map((c) => ({
      url: c?.url,
      name: c?.name,
    })),
    url: o?.url,
    model: o?.model,
  }));

  const RES_SPLASH_ENTRIE = {
    isActive: RES_SPLASH?.isActive,
  };

  return {
    props: JSON.parse(
      JSON.stringify({
        offers: RES_OFFER.data,
        splashData: RES_SPLASH_ENTRIE,
        specialSplashAvailable: SPLASH_AVAILABLE(),
      })
    ),
  };
}
export default function Page(props) {
  const { offers, splashData, specialSplashAvailable } = props;
  const [displaySplash, setDisplaySplash] = useState<boolean>(
    splashData?.isActive
  ); // set false if do not want to show splash page

  const OFFER_DATA: IMY24SpecialOffer = {
    theme: 'dark',
    paginationVariant: 'white',
    headline: {
      lead: 'ข้อเสนอพิเศษ',
      title: 'Special offer',
      description: '',
      hideButtonOnMobile: true,
      buttons: [
        {
          label: 'ดูข้อเสนอทั้งหมด',
          url: MENU_LCV_SPECIAL_OFFER.url,
          icon: 'arrow-forward',
          color: 'button-white',
          isMinWidth: true,
        },
      ],
    },
    items: offers.entries.map((o) => ({
      image: {
        src: o?.img?.url,
        alt: o?.img?.alt ? o?.img?.alt : o?.name,
      },
      category: {
        url: `${MENU_LCV_SPECIAL_OFFER.url}/${o?.category?.[0]?.url}`,
        name: o?.category?.[0]?.name,
      },
      title: o?.name,
      buttons: [
        {
          url: `${MENU_LCV_SPECIAL_OFFER.url}/${o?.category?.[0]?.url}/${o?.url}`,
          label: 'ดูรายละเอียด',
        },
      ],
      modelName: o?.model,
    })),
  };

  const onCloseSplash = () => {
    setDisplaySplash(false);
  };

  return (
    <>
      {!!(
        META_TAG_DATA?.title ||
        META_TAG_DATA?.description ||
        META_TAG_DATA?.img?.url
      ) && (
        <CustomMetatag
          title={META_TAG_DATA?.title}
          description={META_TAG_DATA?.description}
          imgURL={META_TAG_DATA?.img?.url}
        />
      )}
      <main>
        {!!displaySplash &&
          (specialSplashAvailable ? (
            <SplashSpecialEvent onClose={onCloseSplash} />
          ) : (
            <SplashPage {...SPLASH_DATA} onClose={onCloseSplash} />
          ))}
        <LCVHomeBanner {...HERO_BANNER_DATA} isCloseSplash={!displaySplash} />
        <SectionEinsteinEngine {...ENGINE_DATA} />
        <SectionEinsteinGear {...GEAR_DATA} />
        <Lineup {...LINEUP_CAROUSEL_DATA} />
        <SpecialOffer {...OFFER_DATA} />
        <CTA {...CTA_DATA} />
      </main>
      <FooterLCVGroup />
    </>
  );
}

Page.getLayout = function getLayout(page: ReactElement) {
  return <Layout>{page}</Layout>;
};
