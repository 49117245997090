export const MAXDEAL_OTP_SESSION_KEY = 'maxdeal_otp';
export const MAXDEAL_OTP_MINUTE = 3;
export const MAXIMUM_OTP_SESSION_TIME_LEFT = 11;
export const MAXDEAL_THANKYOU_KEY = 'maxdeal_thankyou';

export const MAXDEAL_LEAD_REMARK = 'MaxForceMaxDeal';
export const MAXDEAL_URL = '/campaigns/maxforce-maxdeal';

export enum RequestURL {
  SendOtp = '/send-otp',
  VerifyOtp = '/verify-otp',
}

export enum MaxforceMaxdealStep {
  form,
  otp,
  thankyou,
}
